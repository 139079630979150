import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import "core-js/modules/es.array.push.js";
// import routes from '../router/routes'
import { Toast, showDialog, showToast } from 'vant';
import { defaultFormat } from 'moment';
import moment from 'moment';
import dateBox from '@/components/dateBox';
var Enumerable = require("linq");
const formatDate = function formatDate(date, format) {
  console.log(date);
  var yyyy = ('000' + date.getFullYear()).slice(-4),
    yy = yyyy.slice(-2),
    mm = ('0' + (date.getMonth() + 1)).slice(-2),
    dd = ('0' + date.getDate()).slice(-2),
    mi = ('0' + date.getMinutes()).slice(-2),
    HH = ('0' + date.getHours()).slice(-2),
    ss = ('0' + date.getSeconds()).slice(-2);
  format = format || 'yyyy-mm-dd';
  return format.replace(/yyyy/gi, yyyy).replace(/yy/gi, yy).replace(/MM/gi, mm).replace(/dd/gi, dd).replace(/HH/gi, HH).replace(/mi/gi, mi).replace(/ss/gi, ss);
};
String.prototype.replaceAll = function (s1, s2) {
  return this.replace(new RegExp(s1, "gm"), s2);
};
export default {
  name: 'index',
  components: {
    dateBox
  },
  data() {
    return {
      uploadUrl: '/api/upload',
      id: 0,
      currentDate: new Date(2023, 0, 1),
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 5, 1),
      active: 0,
      answerEmpty: 0,
      showTMS: false,
      showPopover: false,
      showAction: false,
      actions: [{
        text: '选项一'
      }, {
        text: '选项二'
      }, {
        text: '选项三'
      }],
      activeNames: ['1'],
      studentInfo: {},
      myplans: [{
        id: 1,
        name: '11111'
      }, {
        id: 2,
        name: '222222'
      }, {
        id: 3,
        name: '3333333'
      }],
      planTemp: {},
      scale: {
        questionData: []
      },
      answers: [],
      curID: 1,
      curQuestion: {
        // 问题
        answerId: '',
        answerIds: [],
        answer: ''
      },
      curAnswer: {
        //答案
        answerId: '',
        answerIds: [],
        answer: ''
      },
      planId: 1,
      scaleId: 1,
      startTime: '',
      endTime: '',
      groups: []
    };
  },
  methods: {
    async uploadImg(data) {
      //转成file文件
      let blobObj = this.base64ToBlob(data);
      var file = new File([blobObj], 'sign.png', {
        type: blobObj.type,
        lastModified: Date.now()
      });
      //此处为发送请求给后台获取图片路径
      //上传签名图片
      const formData = new FormData();
      formData.append('name', 'qm');
      formData.append('type', 'type');
      formData.append('file', file);
      return this.$http.postfile(this.uploadUrl, formData, '上传中');
    },
    //base64转Blob
    base64ToBlob(base64Data) {
      // console.log(base64Data);
      let arr = base64Data.split(','),
        fileType = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        l = bstr.length,
        u8Arr = new Uint8Array(l);
      while (l--) {
        u8Arr[l] = bstr.charCodeAt(l);
      }
      return new Blob([u8Arr], {
        type: fileType
      });
    },
    onSign(img) {
      var that = this;
      this.uploadImg(img.image).then(res => {
        console.log(res);
        if (res.success) {
          that.curQuestion.answer = res.data;
          that.curQuestion.answerId = 'Sign';
          that.curID++;
        } else {
          that.$toast(res.msg);
        }
        // on close
      });
      ;
    },
    showDescription() {
      // this.$toast(this.scale.remark);
      showDialog({
        width: '280px',
        title: '说明',
        message: this.scale.remark
      }).then(() => {
        // on close
      });
    },
    qr() {
      console.log(this.scale.questionData);
      if (this.curQuestion.types == 'radio') {
        this.curQuestion.answerId = this.curAnswer.answerId;
      } else if (this.curQuestion.types == 'text') {
        this.curQuestion.answerId = '手动输入';
        this.curQuestion.answer = this.curAnswer.answer;
      } else if (this.curQuestion.types == 'checkbox') {
        if (!this.curAnswer.answerIds) {
          showToast('请至少选一个');
          return;
        } else if (this.curAnswer.answerIds.length == 0) {
          showToast('请至少选一个');
          return;
        } else {
          this.curQuestion.answerIds = this.curAnswer.answerIds;
        }
      }

      //根据跳题设置每道题的need属性
      var cid = this.scale.questionData[0].xh;
      var jid = this.scale.questionData[0].jumpId;
      for (var i = 0; i < this.scale.questionData.length; i++) {
        this.scale.questionData[i].need = true;
        if (this.scale.questionData[i].jumpId && i + 1 >= jid) {
          cid = this.scale.questionData[i].xh; //有则更新
          jid = this.scale.questionData[i].jumpId; //有则更新
        }
        //设置跳题
        if (i + 1 > cid && i + 1 < jid && i + 1 <= this.scale.questionData.length) {
          this.scale.questionData[i].need = false;
          this.scale.questionData[i].answerId = null;
          this.scale.questionData[i].answer = null;
        }
      }
      //跳题
      if (this.curQuestion.types == 'radio') {
        if (option.jumpId) {
          console.log('跳到' + option.jumpId + '题');
          this.curID = option.jumpId - 1;
        } else {
          this.curID++;
        }
      } else {
        this.curID++;
      }
    },
    onSelect(option) {
      console.log(this.scale.questionData);
      console.log(this.curAnswer.answerId);
      console.log(this.curAnswer.answerIds);
      console.log(option);
      if (this.curQuestion.types == 'radio') {
        this.curQuestion.answerId = option.options;
        this.curQuestion.answer = option.answer;
        this.curQuestion.score = option.score; //赋分
      } else if (this.curQuestion.types == 'checkbox') {
        this.curQuestion.answerIds = this.curAnswer.answerIds;
        var ans = [];
        this.curQuestion.questionOptionData.forEach(op => {
          if (this.curAnswer.answerIds) {
            if (this.curAnswer.answerIds.indexOf(op.options) > -1) {
              ans.push(op.answer);
            }
          }
        });
        this.curQuestion.answer = ans.join();
      }
      this.curQuestion.jumpId = option.jumpId;
      //this.answers[this.curID] = option.oid;
      //this.curAnswer = null;
      // console.log(this.scale.questionData);
      // console.log(this.scale.questionData);
      //修正need

      for (var i = 0; i < this.scale.questionData.length; i++) {
        this.scale.questionData[i].need = true;
        var cid = this.scale.questionData[i].xh;
        var jid = this.scale.questionData[i].jumpId;
        if (jid) {
          i++;
          //跳题
          while (i + 1 < jid && i + 1 <= this.scale.questionData.length) {
            this.scale.questionData[i].need = false;
            this.scale.questionData[i].answerId = null;
            this.scale.questionData[i].answer = null;
            i++;
          }
        }
      }
      //跳题
      if (this.curQuestion.types == 'radio') {
        if (option.jumpId) {
          console.log('跳到' + option.jumpId + '题');
          this.curID = option.jumpId - 1;
        } else {
          this.curID++;
        }
      }
      // console.log(this.scale.questionData);
    },

    getTest() {
      var that = this;
      that.$http.get('/api/old/getScale/' + this.$data.planId + '/' + this.$data.scaleId, null, '正在加载量表内容，请耐心等待').then(result => {
        console.log(result);
        if (result.success) {
          that.scale = result.data.scale;
          that.recordId = result.data.recordId;
          that.groups = Enumerable.default.from(result.data.scale.questionData).distinct("o=>o.gpTitle").select("o=>o.gpTitle").toArray();
          console.log(that.groups);
          that.scale.questionData.forEach(item => {
            item.need = true;
            item.answer = null;
            item.editable = false;
            if (item.remark) {
              item.prop = JSON.parse(item.remark);
            }
          });
          if (that.scale.questionData.length > 0) {
            that.curID = 0;
          }
          this.showDescription();
        } else {
          showToast({
            title: '标题',
            message: result.msg
          });
          //this.showQYWXCode();
        }
      });
    },

    submit() {
      var that = this;
      //将答案questionData放到data

      var data = [];
      for (let index = 0; index < this.scale.questionData.length; index++) {
        data.push({
          questionId: this.scale.questionData[index].xh,
          answerId: this.scale.questionData[index].answerId,
          answer: this.scale.questionData[index].answer,
          answerIds: this.scale.questionData[index].answerIds,
          jumpId: this.scale.questionData[index].jumpId
        });
      }
      var formData = {};
      formData.recordId = this.recordId;
      formData.planId = this.planId;
      formData.scaleId = this.scaleId;
      formData.startTime = this.$data.startTime;
      formData.endTime = formatDate(new Date(), 'yyyy-MM-dd HH:mi:ss');
      formData.questionParams = data;
      console.log(formData);
      if (!this.check(data)) {
        return;
      }
      that.$http.post('/api/old/scaleSubmit', formData, '正在提交量表，请稍等片刻！').then(result => {
        console.log(result);
        if (result.success) {
          showToast({
            title: '标题',
            message: '已提交'
          });
          showToast('已提交');
          that.$router.push('/old/myScales');
          // this.getAnother();
        } else {
          showToast({
            title: '标题',
            message: result.msg
          });
          //this.showQYWXCode();
        }
      });
    },

    check(data) {
      var i = 0;
      while (i < data.length) {
        if (!data[i].answerId && !data[i].answerIds) {
          showToast('请完成题目' + (i + 1));
          // showToast('请完成题目' + (i + 1));
          this.curID = i;
          return false;
        } else if (data[i].jumpId) {
          i = data[i].jumpId - 1;
        } else {
          i++;
        }
      }
      return true;
    },
    getValue(math) {
      var that = this;
      var item = null;
      this.scale.questionData.forEach(res => {
        math = math.split('{' + res.xh + '}').join(res.score ? res.score : res.answer);
      });
      return eval(math);
    },
    updatevalue(val) {
      console.log('curAnswer', this.curAnswer);
      this.$data.curQuestion.answerId = '手动输入';
      this.$data.curQuestion.answer = val;
      // this.$data.curQuestion[this.curID].answer=val;
      console.log('this.$data.curAnswer.answer', this.$data.curAnswer.answer);
      this.curID++;
    },
    getAnother() {
      this.$http.get('/api/ycf/getAnotherScale/', null, '正在请求其他未完成的量表，请稍等片刻！').then(result => {
        console.log(result);
        if (result.success) {
          this.$data.startTime = result.time;
          console.log(result.data);
          showToast('已提交');
          if (result.data) {
            this.$data.id = result.data.id;
            console.log(this.$data.id);
          } else {
            showToast('没有更多了');
            setTimeout(() => {
              this.$router.push({
                path: './index'
              });
            }, 1500);
          }
        } else {
          showToast({
            title: '标题',
            message: result.msg
          });
          //this.showQYWXCode();
        }
      });
    }
  },

  created() {},
  mounted() {
    this.stu = JSON.parse(localStorage.getItem('stu'));
    console.log(this.stu);
    var userstr = localStorage.getItem('user');
    var user = JSON.parse(userstr);
    var query = this.$route.query;
    this.scaleId = query.scaleId;
    this.planId = query.planId;
    this.studentInfo = user.studentInfo;
    this.getTest();
  },
  watch: {
    id: function (newVal) {
      var queryid = this.$route.query.id;
      if (queryid != this.id) {
        this.getTest();
      }
      // this.getTest();
    },

    curID: function (newVal, oldVal) {
      var that = this;
      console.log(newVal);
      if (newVal < 0) {
        showToast({
          title: '标题',
          message: '这是第一题，不能再往前了'
        });
        // showToast('这是第一题，不能再往前了');
        newVal = 0;
        that.curID = 0;
      }
      if (newVal > this.scale.questionData.length - 1) {
        showToast({
          title: '提示',
          message: '这是最后一个了，不能再往后了'
        });
        // showToast('这是最后一个了，不能再往后了');
        newVal = this.scale.questionData.length - 1;
        that.curID = this.scale.questionData.length - 1;
      }
      this.curQuestion = this.scale.questionData[newVal];
      console.log(this.curQuestion);

      //设置readonly
      that.curQuestion.readonly = false;
      that.curAnswer.readonly = false;
      //计算默认值
      if (that.curQuestion.remark) {
        var obj = JSON.parse(that.curQuestion.remark);
        if ((!that.curQuestion.answer || obj.default.aways) && obj.default.answer) {
          that.curQuestion.answer = that.getValue(obj.default.answer);
        }
        if ((!that.curQuestion.answerId || obj.default.aways) && obj.default.answerId) {
          that.curQuestion.answerId = that.getValue(obj.default.answerId);
        }
        if ((!that.curQuestion.score || obj.default.aways) && obj.default.score) {
          that.curQuestion.score = that.getValue(obj.default.score);
        }
        if (obj.readonly) {
          that.curQuestion.readonly = true;
          that.curAnswer.readonly = true;
          //that.curQuestion.answer = eval("that.recordId");
        }
      }

      //赋值到 curAnswer
      this.curAnswer.answerId = this.curQuestion.answerId;
      this.curAnswer.answerIds = this.curQuestion.answerIds;
      if (that.curQuestion.types != 'date') {
        that.curAnswer.answer = that.curQuestion.answer;
      }

      //计算未完成数目
      var count = 0;
      this.scale.questionData.forEach(item => {
        if (item.need && !item.answer && !item.answerIds) {
          count++;
        }
      });
      this.answerEmpty = count;
    },
    'curAnswer.answer': function (newVal, oldVal) {
      var that = this;
      console.log('curAnswer.answer', newVal);
    }
  }
};